import clsx from 'clsx';
import React, { useState } from 'react';

import { AccordeonFieldsFragment, ComponentInternalAccordeonSection } from 'api/graphql-types';

import { iterateHeading } from 'utils/iterate-heading';
import { SidebarLink } from 'components/sidebar-link/sidebar-link';
import { renderDownloadLink } from 'modules/downloads/downloads';
import { Typography } from 'components/typography';

import { AccordeonSection, IOpenSection } from './section/accordeon-section';
import noteStyles from './accordeon-note.module.scss';
import moduleStyles from './accordeon-module.module.scss';

interface AccordeonProps {
	module: AccordeonFieldsFragment;
	expandableSections?: boolean;
	hasTitle?: boolean;
	variant?: 'note' | 'module';
}

const Accordeon = ({ variant = 'module', module, hasTitle, expandableSections }: AccordeonProps): React.JSX.Element => {
	const styles = variant === 'note' ? noteStyles : moduleStyles;
	const { sections } = module;
	const backgroundColor = module?.defaultModuleOptions?.backgroundColor;
	const isSidebarEnabled = module.sidebar != null;
	const rowStyles = isSidebarEnabled ? styles.rowWithSidebar : styles.rowFullWidth;
	const moduleHeadingTag = module.accordeonTitle?.type ?? (hasTitle ? 'h3' : 'h2');

	const [openSection, setOpenSection] = useState<IOpenSection>();

	return (
		<>
			{module?.accordeonTitle?.value && (
				<Typography tag={moduleHeadingTag} variant="h2">
					{module.accordeonTitle.value}
				</Typography>
			)}

			<div className={clsx(styles.row, rowStyles)}>
				<section className={styles.content}>
					{sections?.map((section, sectionIndex: number) => (
						<AccordeonSection
							key={`section${sectionIndex}`}
							section={section as ComponentInternalAccordeonSection}
							sectionIndex={sectionIndex}
							backgroundColor={backgroundColor}
							styles={styles}
							variant={variant}
							openSection={openSection}
							setOpenSection={setOpenSection}
							expandableSections={expandableSections}
							headingType={module?.accordeonTitle?.value ? iterateHeading(moduleHeadingTag) : moduleHeadingTag}
						/>
					))}
				</section>

				{isSidebarEnabled && (
					<section className={styles.sidebar}>
						{module?.sidebar?.title?.value && (
							<Typography tag={module.sidebar.title?.type || 'h2'} variant="h4">
								{module.sidebar.title.value}
							</Typography>
						)}
						<ul>
							{module.sidebar?.links?.map((link, idx) => (
								<li key={`${idx}-${link?.title}`}>
									<SidebarLink href={link?.href?.url} description={link?.description} title={link?.title} />
								</li>
							))}
							{module.sidebar?.files?.data?.map((file) => (
								<li key={file?.attributes?.hash}>
									<SidebarLink
										href={file?.attributes?.url}
										description={file?.attributes?.caption}
										title={renderDownloadLink(
											{
												ext: file.attributes?.ext || '',
												size: file.attributes?.size || 0
											},
											file?.attributes?.name
										)}
									/>
								</li>
							))}
						</ul>
					</section>
				)}
			</div>
		</>
	);
};

export default Accordeon;
