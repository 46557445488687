import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { ComponentInternalAccordeonSection } from 'api/graphql-types';

import { ResizeObserver } from '@juggle/resize-observer';
import { iterateHeading } from 'utils/iterate-heading';
import { Icon } from 'components/icon/icon';
import { ITypographyHeadingVariants, Typography } from 'components/typography';

import CarotUp from 'public/assets/icons/carot-up.svg';
import CarotDown from 'public/assets/icons/carot-down.svg';

import { AccordeonItem, IOpenItem } from '../item/accordeon-item';

export interface IOpenSection {
	section: number;
}

export interface AccordeonSectionProps {
	section: ComponentInternalAccordeonSection;
	sectionIndex: number;
	backgroundColor?: string;
	styles: any;
	variant: 'note' | 'module';
	setOpenSection: React.Dispatch<React.SetStateAction<IOpenSection | undefined>>;
	openSection?: IOpenSection;
	expandableSections?: boolean;
	headingType?: ITypographyHeadingVariants;
}

export const AccordeonSection = ({
	section,
	sectionIndex,
	backgroundColor,
	styles,
	variant,
	openSection,
	expandableSections,
	headingType,
	setOpenSection
}: AccordeonSectionProps): React.JSX.Element => {
	const [openItem, setOpenItem] = useState<IOpenItem>();
	const headerRef = useRef<HTMLSpanElement | null>(null);
	const bodyRef = useRef<HTMLDivElement | null>(null);

	const iconOpen = <CarotUp />;
	const iconClose = <CarotDown />;

	const [headerHeight, setHeaderHeight] = useState<string>('auto');
	const [bodyHeight, setBodyHeight] = useState<string>('auto');
	const [height, setHeight] = useState<string>('auto');

	const background = backgroundColor === 'white' ? styles.grey : backgroundColor === 'grey' && styles.white;
	const isOpen = openSection?.section === sectionIndex;

	const onClick = (): void => {
		setOpenSection(isOpen ? undefined : { section: sectionIndex });
	};

	const onKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>): void => {
		if (event.key === 'Enter') {
			setOpenSection(isOpen ? undefined : { section: sectionIndex });
		}
	};

	// Individual observers for header and body to track changes in element size
	const headerObserver = useRef(
		new ResizeObserver((entries) => {
			const { blockSize } = entries[0].borderBoxSize[0];
			setHeaderHeight(String(blockSize));
		})
	);

	const bodyObserver = useRef(
		new ResizeObserver((entries) => {
			const { blockSize } = entries[0].borderBoxSize[0];
			setBodyHeight(String(blockSize));
		})
	);

	// Sets wrapper height when opened or on size change
	useEffect(() => {
		if (isOpen) {
			setHeight('auto');
		} else {
			setHeight(`${headerHeight}px`);
		}
	}, [isOpen, headerHeight, bodyHeight]);

	// Assign observers to header and body ref when loaded
	useEffect(() => {
		if (headerRef.current) headerObserver.current.observe(headerRef.current);
		if (bodyRef.current) bodyObserver.current.observe(bodyRef.current);

		return () => {
			if (headerRef.current) headerObserver.current.unobserve(headerRef.current);
			if (bodyRef.current) bodyObserver.current.unobserve(bodyRef.current);
		};
	}, [headerRef, bodyRef]);

	return (
		<>
			{expandableSections ? (
				<div className={`${clsx(styles.section, isOpen && styles.openSection)} ${background}`} style={{ height }}>
					<span
						ref={headerRef}
						className={styles.itemHeader}
						onClick={onClick}
						role="button"
						tabIndex={0}
						onKeyDown={onKeyDown}
						aria-expanded={isOpen}
					>
						{section?.accordeonSectionName && (
							<Typography className={styles.accordeonName} tag={headingType || 'div'} variant="h5">
								{section.accordeonSectionName}
							</Typography>
						)}
						<span className={styles.icon}>
							{isOpen ? <Icon customIcon={iconOpen} size="16px" /> : <Icon customIcon={iconClose} size="16px" />}
						</span>
					</span>

					<div className={styles.description} ref={bodyRef}>
						{section?.items?.map((item, itemIndex: number) => (
							<AccordeonItem
								key={item?.itemTitle}
								item={item}
								itemIndex={itemIndex}
								sectionIndex={sectionIndex}
								backgroundColor={backgroundColor}
								openItem={openItem}
								styles={styles}
								variant={variant}
								setOpenItem={setOpenItem}
							/>
						))}
					</div>
				</div>
			) : (
				<div className={styles.section}>
					{section?.accordeonSectionName && (
						<Typography tag={headingType || 'div'} variant="h5">
							{section.accordeonSectionName}
						</Typography>
					)}
					{section?.items?.map((item, itemIndex: number) => (
						<AccordeonItem
							key={item?.itemTitle}
							item={item}
							itemIndex={itemIndex}
							sectionIndex={sectionIndex}
							backgroundColor={backgroundColor}
							openItem={openItem}
							styles={styles}
							variant={variant}
							setOpenItem={setOpenItem}
							headingType={section.accordeonSectionName ? iterateHeading(headingType) : headingType}
						/>
					))}
				</div>
			)}
		</>
	);
};

export default AccordeonSection;
